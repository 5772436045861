import { Api } from "@/models/class/api.class";
import { CashInOutCreateResponseDto } from "@/models/interface/cash-in-out";
import {
  ResponseCashInOutTypeDTO,
  ResponseListCashOut,
} from "@/models/interface/cashManagement.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { TruckingCashInOutCreateRequestDto } from "@/models/interface/trucking-cash-in-out";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class TruckingCashInOutService extends HttpClient {
  constructor() {
    super();
  }

  getCashInOutList(
    params?: RequestQueryParamsModel
  ): Promise<ResponseListCashOut> {
    return this.get<ResponseListCashOut>(Api.TruckingCashInOutList, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadCashInOutList(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.TruckingCashInOutListDownload, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getCashInOutListType(
    params: RequestQueryParamsModel
  ): Promise<ResponseCashInOutTypeDTO[]> {
    return this.get<ResponseCashInOutTypeDTO[]>(Api.TruckingCashInOutTypes, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  create(
    payload: TruckingCashInOutCreateRequestDto
  ): Promise<CashInOutCreateResponseDto> {
    return this.post<
      CashInOutCreateResponseDto,
      TruckingCashInOutCreateRequestDto
    >(Api.CashOut, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  update(
    id: string,
    payload: TruckingCashInOutCreateRequestDto
  ): Promise<CashInOutCreateResponseDto> {
    return this.put<
      CashInOutCreateResponseDto,
      TruckingCashInOutCreateRequestDto
    >(`${Api.CashOut}/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printDetail(id: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.CashInOutPrint}/${id}/detail`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printSummary(id: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.CashInOutPrint}/${id}/summary`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const truckingCashInOutService = new TruckingCashInOutService();
